import React, {useEffect, useState} from 'react';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import {format} from 'date-fns';
import {uk} from 'date-fns/locale'
import s from './wordExport.module.css'

const WordExport = () => {

    const [data, setData] = useState({
        date: format(new Date(), 'd MMMM yyyy', {locale: uk}),
        firstName: 'Валентин',
        lastName: 'Машонкін',
        secondName: 'Петрович',
        initials: '',
        numberContract: '56/5 CM',
        gasHeater: 'Ariston HSX 24 FF',
        serialNumber: '123456789',
        power: '24',
        area: '',
        district: '',
        city: 'смт. Слобожанське',
        street: 'вул. Н. Данченко 25 кв 48',
        tel: '+38 050 253 12 35',
    });

    const updateData = (partialData) => {
        setData((prevData) => ({
            ...prevData,
            ...partialData,
        }));
    };

    useEffect(() => {
        // После инициализации data, обновляем initials
        setData(prevData => ({
            ...prevData,
            initials: `${prevData.firstName.charAt(0)}.${prevData.secondName.charAt(0)}.`,
        }));
    }, [data.firstName, data.secondName]);

    const generateDocument = async (template, docum) => {
        try {
            const response = await fetch(template);
            const content = await response.arrayBuffer();

            const zip = new PizZip(content);
            const doc = new Docxtemplater().loadZip(zip);
            doc.setData(data);
            doc.render();

            const resultBuffer = doc.getZip().generate({type: 'blob'});
            const resultBlob = new Blob([resultBuffer], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(resultBlob);
            link.download = `${data.lastName}-${data.street.replace(/(\. |[.]| )/g, "-")}-${docum}`;
            link.click();
        } catch (error) {
            console.error('Error generating Word document:', error);
        }
    };

    const handleExportdogNewBuil = () => {
        generateDocument(require('../templates/dogNewBuild.docx'), 'договор');
    };
    const handleExportdogAllKlients = () => {
        generateDocument(require('../templates/dogAllKlients.docx'), 'договор');
    };

    const handleExportActAll = () => {
        generateDocument(require('../templates/actAll.docx'), 'акт');
    };

    const handleExportActNewBuild = () => {
        generateDocument(require('../templates/actNewBuild.docx'), 'акт');
    };

    return (
        <div className={s.container}>
            <h1 className={s.title}>Форма для создания договоров и актов</h1>
            <div className={s.content}>
                <div className={s.wrapper}>
                    <div className={s.input__wrapper}>
                        <label htmlFor="numberContract" className={s.label}>№ Договора <span>Например:</span> 28/4
                                                                            СМ</label>
                        <input onChange={(event) => updateData({numberContract: event.target.value})} type="text"
                               className={s.input}
                               id={'numberContract'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.numberContract}</div>
                    </div>

                    <div className={s.input__wrapper}>
                        <label htmlFor="lastName" className={s.label}>Фамилия</label>
                        <input onChange={(event) => updateData({lastName: event.target.value})} type="text"
                               className={s.input}
                               id={'lastName'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.lastName}</div>
                    </div>

                    <div className={s.input__wrapper}>
                        <label htmlFor="firstName" className={s.label}>Имя</label>
                        <input onChange={(event) => updateData({firstName: event.target.value})} type="text"
                               className={s.input}
                               id={'firstName'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.firstName}</div>
                    </div>

                    <div className={s.input__wrapper}>
                        <label htmlFor="secondName" className={s.label}>Отчество</label>
                        <input onChange={(event) => updateData({secondName: event.target.value})} type="text"
                               className={s.input}
                               id={'secondName'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.secondName}</div>
                    </div>

                    <div className={s.input__wrapper}>
                        <label htmlFor="gasHeater" className={s.label}>Марка и модель котла</label>
                        <input placeholder={data.gasHeater}
                               onChange={(event) => updateData({gasHeater: event.target.value})} type="text"
                               className={s.input}
                               id={'gasHeater'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.gasHeater}</div>
                    </div>

                    <div className={s.input__wrapper}>
                        <label htmlFor="serialNumber" className={s.label}>Серийный номер</label>
                        <input placeholder={data.serialNumber}
                               onChange={(event) => updateData({serialNumber: event.target.value})} type="text"
                               className={s.input}
                               id={'serialNumber'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.serialNumber}</div>
                    </div>

                    <div className={s.input__wrapper}>
                        <label htmlFor="power" className={s.label}>Мощность <span>только числа</span></label>
                        <input placeholder={data.power} onChange={(event) => updateData({power: event.target.value})}
                               type="text"
                               className={s.input}
                               id={'power'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.power}</div>
                    </div>

                    <div className={s.input__wrapper}>
                        <label htmlFor="city" className={s.label}>Населенный пункт <span>Например:</span> м.
                                                                  Дніпро</label>
                        <input placeholder={data.city} onChange={(event) => updateData({city: event.target.value})}
                               type="text"
                               className={s.input}
                               id={'city'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.city}</div>
                    </div>
                    <div className={s.input__wrapper}>
                        <label htmlFor="area" className={s.label}>Область <span>Поле не обязательное</span></label>
                        <input placeholder={data.area} onChange={(event) => updateData({area: event.target.value})}
                               type="text"
                               className={s.input}
                               id={'area'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>если не нужно, то и не писать</div>
                    </div>
                    <div className={s.input__wrapper}>
                        <label htmlFor="district" className={s.label}>Район <span>Поле не обязательное</span></label>
                        <input placeholder={data.district}
                               onChange={(event) => updateData({district: event.target.value})}
                               type="text"
                               className={s.input}
                               id={'district'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>если не нужно, то и не писать</div>
                    </div>
                    <div className={s.input__wrapper}>
                        <label htmlFor="street" className={s.label}>Адрес <span>Например:</span> пр. Нигояна 25 кв
                                                                    10</label>
                        <input placeholder={data.street} onChange={(event) => updateData({street: event.target.value})}
                               type="text"
                               className={s.input}
                               id={'street'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.street}</div>
                    </div>

                    <div className={s.input__wrapper}>
                        <label htmlFor="tel" className={s.label}>Номер телефона</label>
                        <input placeholder={data.tel} onChange={(event) => updateData({tel: event.target.value})}
                               type="text"
                               className={s.input}
                               id={'tel'}/>
                    </div>
                    <div className={s.text__wrapper}>
                        <div className={s.text__output}>{data.tel}</div>
                    </div>
                </div>
                <div className={s.section__download}>
                    <div className={s.instruction}>Заполняем все поля соблюдая указания и смотря на
                                                   примеры. <br/> Далее, в
                                                   зависимости от того, какие
                                                   документы нужны, выбираем раздел ниже и нажимаем соответствующие
                                                   кнопки для сохранения
                                                   документа на компьютер. <br/> Затем открываем и печатаем
                    </div>
                    <div className={s.new__building}>
                        <h2>Документы для новостроев</h2>
                        <div className={s.buttons}>
                            <button className={[s['custom-btn'], s['btn-7']].join(' ')}
                                    onClick={handleExportdogNewBuil}><span>Договор</span>
                            </button>
                            <button className={[s['custom-btn'], s['btn-7']].join(' ')}
                                    onClick={handleExportActNewBuild}><span>Акт границ</span>
                            </button>
                        </div>
                    </div>
                    <div className={s.other}>
                        <h2>Документы для всех остальных</h2>
                        <div className={s.buttons}>
                            <button className={[s['custom-btn'], s['btn-7']].join(' ')}
                                    onClick={handleExportdogAllKlients}><span>Договор</span>
                            </button>
                            <button className={[s['custom-btn'], s['btn-7']].join(' ')}
                                    onClick={handleExportActAll}><span>Акт границ</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WordExport;
